import React, { useState, useRef, useEffect } from "react";
import ImageResizer from "./ImageResizer";

import { TextField, Autocomplete } from "@mui/material";
import LabelsDialogBox from "./LabelsDialogBox";
import MapsDialogBox from "./MapsDialogBox";
import PosterView from "./PosterView";
import { convertToISODate, convertToDatabaseDate } from "./utils/isoDateTime";
import { checkSanity } from "./PosterViewComments";
import { POSTERS_API_URL } from "./apis/posterApi";

const initFormData = {
  id: "",
  creationDate: "",
  title: "",
  subtitle: "",
  web: "",
  beginDateISO: "",
  endDateISO: "",
  beginDate: "",
  endDate: "",
  beginTime: "",
  endTime: "",
  town: "",
  region: "",
  geoPt: "",
  description: "",
  comments: "",
  labels: "",
  others: "",
  labelsList: "",
  publisher: "",
  pictureKey: "",
};

const PosterEditor = ({
  poster,
  userProfile,
  isReadOnly,
  formRef,
  onInputsModified,
  onResizedPicture,
}) => {
  const [formData, setFormData] = useState(initFormData);

  const [posterImage, setPosterImage] = useState("");

  const [pictureIsRequired, setPictureIsRequired] = useState(true);
  const [pictureResize, setPictureResize] = useState(false);
  const [pictureFileName, setPictureFileName] = useState(null);

  const fileInputRef = useRef(null);

  const [openLabelsDialog, setOpenLabelsDialog] = useState(false);
  const [openMapsDialog, setOpenMapsDialog] = useState(false);

  const [regions, setRegions] = useState([]);
  const [towns, setTowns] = useState([]);

  useEffect(() => {
    const reset = () => {
      formRef.current.reset();
      setFormData(initFormData);
      setPosterImage("");
    };
    //

    setPictureResize(false);
    setPictureIsRequired(true);
    setPictureFileName(null);

    //
    if (poster) {
      fromPosterToFormData(poster);
      setPictureIsRequired(false);
    } else {
      reset();
      if (userProfile) {
        setFormData((formData) => ({
          ...formData,
          publisher: userProfile.email,
        }));
      }
    }
  }, [poster, userProfile, formRef]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_POSTERS_APP}/region`)
      .then((response) => response.json())
      .then((data) => setRegions(data))
      .catch((error) => console.error("Error fetching regions:", error));
  }, []);

  useEffect(() => {
    if (formData.region) {
      fetch(`${process.env.REACT_APP_POSTERS_APP}/region/${formData.region}`)
        .then((response) => response.json())
        .then((data) => setTowns(data))
        .catch((error) => console.error("Error fetching towns:", error));
    }
  }, [formData.region]);

  const fromFormDataToPoster = () => {
    return {
      id: formData.id,
      creationDate: formData.creationDate,
      title: formData.title,
      subtitle: formData.subtitle,
      webLink: formData.web,
      dateBegin: formData.beginDate,
      dateEnd: formData.endDate,
      timeBegin: formData.beginTime,
      timeEnd: formData.endTime,
      town: formData.town,
      region: formData.region,
      geoPt: {
        latitude: formData.geoPt ? formData.geoPt.split(",")[0] : "",
        longitude: formData.geoPt ? formData.geoPt.split(",")[1] : "",
      },
      description: formData.description,
      comments: formData.comments,
      labelsList: formData.labelsList ? formData.labelsList.split(",") : [],
      publisherId: btoa(formData.publisher),
      pictureKey: formData.pictureKey,
      pictureUrl: posterImage,
    };
  };

  const fromPosterToFormData = (poster) => {
    setFormData({
      id: poster.id,
      creationDate: poster.creationDate,
      title: poster.title,
      subtitle: poster.subtitle,
      web: poster.webLink,
      beginDateISO: convertToISODate(poster.dateBegin),
      endDateISO: convertToISODate(poster.dateEnd),
      beginDate: poster.dateBegin,
      endDate: poster.dateEnd,
      beginTime: poster.timeBegin,
      endTime: poster.timeEnd,
      town: poster.town,
      region: poster.region,
      geoPt: poster.geoPt.latitude + ", " + poster.geoPt.longitude,
      description: poster.description,
      comments: poster.comments,
      labels: poster.labelsList.toString() || "",
      others: "",
      labelsList: poster.labelsList.toString() || "",
      publisher: atob(poster.publisherId),
      pictureKey: poster.pictureKey,
    });
    setPosterImage(
      poster.pictureUrl
        ? poster.pictureUrl
        : poster.pictureKey
        ? `${POSTERS_API_URL}/picture/${encodeURIComponent(poster.pictureKey)}`
        : ""
    );
  };

  const notifyOnModified = () => {
    if (onInputsModified) onInputsModified();
  };

  const handleOpenMapsDialog = () => {
    setOpenMapsDialog(true);
  };

  const handleCancelMapsDialog = () => {
    setOpenMapsDialog(false);
  };

  const handleSubmitMapsDialog = (pos) => {
    setFormData({
      ...formData,
      geoPt: pos,
    });
    notifyOnModified();
    setOpenMapsDialog(false);
  };

  const handleClickOpenLabelsDialog = () => {
    setOpenLabelsDialog(true);
  };

  const handleCloseLabelsDialog = () => {
    setOpenLabelsDialog(false);
  };

  const setInputLabels = (labels) => {
    let aux = formData.others ? `,${formData.others}` : "";

    setFormData({
      ...formData,
      labels: labels,
      labelsList: labels + aux,
    });
  };

  const convertToCamelCase = (str) => {
    return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (e.target.validationMessage) {
      e.target.setCustomValidity("");
    }

    setFormData({
      ...formData,
      [convertToCamelCase(name)]: value,
    });

    notifyOnModified();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInputCustomValidity(e.target, "");
      setPictureFileName(file.name.split(".").slice(0, -1).join("."));

      const reader = new FileReader();
      reader.onload = (event) => {
        setPosterImage(event.target.result);
        notifyOnModified();
      };
      reader.onloadend = (event) => {
        const size = event.total;
        if (size / 1024 > 150) {
          if (!pictureResize) {
            setPictureResize(true);
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOnLoadPicture = (e) => {
    const img = e.target;
    const height = img.naturalHeight;
    const width = img.naturalWidth;

    if ((height < 480 && width < 480) || (height < 480 && width < 480)) {
      setInputCustomValidity(fileInputRef.current, "L'imatge és massa petita");
    }
  };

  const handlePictureResize = (resizedSrc, resizedFile) => {
    setPictureResize(false);
    if (resizedFile !== null) {
      setPosterImage(resizedSrc);
      if (onResizedPicture) onResizedPicture(resizedFile);
    }
  };

  const setInputCustomValidity = (input, message) => {
    input.setCustomValidity(message);
    if (message !== "") {
      input.reportValidity();
    }
  };

  const handleInputBeginDateChange = (e) => {
    setFormData({
      ...formData,
      beginDateISO: e.target.value,
      beginDate: convertToDatabaseDate(e.target.value),
    });
    notifyOnModified();
  };

  const handleInputEndDateChange = (e) => {
    setInputCustomValidity(e.target, "");
    if (checkEndDate(e)) {
      setFormData({
        ...formData,
        endDateISO: e.target.value,
        endDate: convertToDatabaseDate(e.target.value),
      });
      notifyOnModified();
    }
  };

  const checkEndDate = (e) => {
    const dateBegin = new Date(formData.beginDateISO);
    const dateEnd = new Date(e.target.value);
    const now = new Date();
    now.setHours(0, 0, 0, 0); // it allows to set events in the current day
    let ret = true;

    if (isNaN(dateBegin)) {
      setInputCustomValidity(e.target, "Primer Data Inici");
      ret = false;
    } else if (now > dateEnd) {
      setInputCustomValidity(e.target, "Cal que finalitzi en el futur");
      ret = false;
    } else if (dateBegin > dateEnd) {
      setInputCustomValidity(
        e.target,
        "Cal que el final sigui després del inici"
      );
      ret = false;
    }
    return ret;
  };

  const checkWebPattern = (e) => {
    let aux = formData.web;

    const PROTOCOL = "http://";
    const SECURED_PROTOCOL = "https://";
    if (aux.startsWith(PROTOCOL)) {
      aux = aux.substring(PROTOCOL.length);
    } else if (aux.startsWith(SECURED_PROTOCOL)) {
      aux = aux.substring(SECURED_PROTOCOL.length);
    }

    if (aux.endsWith("/")) {
      aux = aux.slice(0, -1);
    }

    setFormData({
      ...formData,
      web: aux,
    });

    return true;
  };

  const handleInputOtherLabelsBlur = () => {
    //
    if (formData.others === "") {
      return;
    }
    //
    let aux = "";
    if (formData.labels) {
      aux = formData.labels + ",";
    }
    let labelsArray = formData.others.split(",");
    labelsArray = labelsArray.map((label) => label.trim());
    let aux2 = labelsArray.join(",");
    //
    setFormData((prevFormData) => ({
      ...prevFormData,
      others: aux2,
      labelsList: aux + aux2,
    }));
  };

  const handleInputCommentsBlur = (e) => {
    const isValid = checkSanity(e.target.value);

    if (!isValid) {
      setInputCustomValidity(e.target, "Sols es permeten <a>, <b> y <br>.");
    }
  };

  return (
    <div
      className="poster-editor"
      style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}
    >
      <div
        className="poster-editor-inputs"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          width: "320px",
        }}
      >
        <input type="hidden" name="id" value={formData.id} />
        <input
          type="hidden"
          name="creation-date"
          value={formData.creationDate}
        />
        <input type="hidden" name="labels-list" value={formData.labelsList} />
        <input type="hidden" name="begin-date" value={formData.beginDate} />
        <input type="hidden" name="end-date" value={formData.endDate} />
        <input type="hidden" name="picture-key" value={formData.pictureKey} />
        <ol>
          <li>
            <TextField
              label="Títol"
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              placeholder="Títol general on englobar el Cartell"
              disabled={isReadOnly}
              inputProps={{
                pattern: ".{5,}",
                minLength: "5",
                title: "El títol cal que tingui almenys 5 caràcters",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
            />
          </li>
          <li>
            <TextField
              label="Subtítol"
              type="text"
              name="subtitle"
              value={formData.subtitle}
              onChange={handleInputChange}
              placeholder="Títol específic del Cartell"
              disabled={isReadOnly}
              inputProps={{
                pattern: ".{5,}",
                minLength: "5",
                title: "El subtitle títol cal que tingui almenys 5 caràcters",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
            />
          </li>
          <li>
            <TextField
              label="Imatge del poster"
              name="picture"
              type="file"
              onChange={handleFileChange}
              disabled={isReadOnly}
              required={pictureIsRequired}
              inputProps={{
                accept: "image/*",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
              inputRef={fileInputRef}
            />
            <br />
          </li>

          <li>
            <TextField
              name="description"
              label="Descripció"
              value={formData.description}
              onChange={handleInputChange}
              required
              multiline
              rows={4} // Set the number of rows for the Textarea
              disabled={isReadOnly}
              inputProps={{
                maxLength: 1200,
                minLength: 50,
                pattern: ".{50,}",
                title: "Almenys 50 caràcters.",
              }}
              placeholder="Descripció del cartell. Molt important pel SEO. Permet <ul>, <li>, <b>, <i>, <p> i <br>."
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
            />
          </li>
          <li>
            <TextField
              label="Web"
              type="text"
              name="web"
              disabled={isReadOnly}
              value={formData.web}
              onChange={handleInputChange}
              onBlur={checkWebPattern}
              placeholder="www.web_del_cartell.com"
              inputProps={{
                pattern:
                  "([a-zA-Z0-9]([a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,6}$",
                title: "Cal la pàgina principal sense /el/que/sigui",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
            />
          </li>
          <li>
            <TextField
              label="Data inici"
              type="date"
              name="begin-dateISO"
              value={formData.beginDateISO}
              onChange={handleInputBeginDateChange}
              required
              placeholder="Quan comencen les activitats del cartell"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isReadOnly}
              fullWidth
              variant="outlined"
            />
          </li>
          <li>
            <TextField
              label="Hora inici"
              type="time"
              name="begin-time"
              value={formData.beginTime}
              onChange={handleInputChange}
              title="Opcionalment entra hora en que acaba"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isReadOnly}
              fullWidth
              variant="outlined"
            />
          </li>
          <li>
            <TextField
              label="Data finalització"
              type="date"
              name="end-dateISO"
              value={formData.endDateISO}
              onChange={handleInputEndDateChange}
              required
              placeholder="Quan finalitzen les activitats del cartell"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isReadOnly}
              fullWidth
              variant="outlined"
            />
          </li>
          <li>
            <TextField
              label="Hora finalització"
              type="time"
              name="end-time"
              value={formData.endTime}
              onChange={handleInputChange}
              title="Opcionalment entra hora en que acaba"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isReadOnly}
              fullWidth
              variant="outlined"
            />
          </li>
          <li>
            <Autocomplete
              options={regions}
              value={regions.includes(formData.region) ? formData.region : null}               
              disabled={isReadOnly}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleInputChange({
                    target: { name: "region", value: newValue },
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Comarca"
                  name="region"
                  required
                  placeholder="Comarca on es fa?"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </li>
          <li>
            <Autocomplete
              options={towns}
              value={formData.town}
              freeSolo
              disabled={isReadOnly}
              onChange={(event, newValue) => {
                handleInputChange({
                  target: { name: "town", value: newValue },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Població"
                  name="town"
                  onChange={handleInputChange}
                  required
                  placeholder="Poble on es fa?"
                  inputProps={{
                    ...params.inputProps,
                    minLength: 2,
                    pattern: ".{2,}",
                    title: "On es fa?",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </li>
          <li>
            <TextField
              label="Coodenades"
              type="text"
              name="geo-pt"
              value={formData.geoPt}
              onClick={handleOpenMapsDialog}
              disabled={isReadOnly}
              required
              placeholder="Clicka per obrir maps"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
            />
            {openMapsDialog && (
              <MapsDialogBox
                onCancel={handleCancelMapsDialog}
                onSubmit={handleSubmitMapsDialog}
              />
            )}
          </li>
          <li>
            <TextField
              label="Comentaris"
              type="text"
              name="comments"
              value={formData.comments}
              onChange={handleInputChange}
              onBlur={handleInputCommentsBlur}
              placeholder="Comentaris associats al cartell. Es pot usar <a>, <b> i <br>."
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isReadOnly}
            />
          </li>
          <li>
            <TextField
              label="Qui ho publica?"
              type="text"
              name="publisher"
              value={formData.publisher}
              onChange={handleInputChange}
              required
              placeholder="email de qui ho publica"
              pattern=".{3,}"
              title="email de qui ho pública"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
              disabled={isReadOnly}
              fullWidth
              variant="outlined"
            />
          </li>
          <li>
            <TextField
              label="Etiquetes"
              name="labels"
              value={formData.labels}
              onClick={handleClickOpenLabelsDialog}
              readOnly
              required
              placeholder="Afegeix Etiquetes"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isReadOnly}
              fullWidth
              variant="outlined"
            />
            <LabelsDialogBox
              open={openLabelsDialog}
              onClose={handleCloseLabelsDialog}
              inputLabels={formData.labels}
              setInputLabels={setInputLabels}
              userLabels={
                userProfile
                  ? userProfile.labelsList
                    ? userProfile.labelsList
                    : []
                  : []
              }
            />
          </li>
          <li>
            <TextField
              label="Altres etiquetes"
              type="text"
              name="others"
              value={formData.others}
              onChange={handleInputChange}
              onBlur={handleInputOtherLabelsBlur}
              placeholder="Etiqueta, Etiqueta,... "
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isReadOnly}
              fullWidth
              variant="outlined"
            />
          </li>
        </ol>
      </div>
      <div style={{ marginTop: "-16px" }}>
        <PosterView
          poster={fromFormDataToPoster(formData)}
          userProfile={userProfile}
          onLoadPicture={handleOnLoadPicture}
        />
        {posterImage && pictureResize && (
          <ImageResizer
            imageSrc={posterImage}
            fileName={pictureFileName + "_resized.jpg"}
            onResize={handlePictureResize}
          />
        )}
      </div>
    </div>
  );
};

export default PosterEditor;
