import "./App.css";
import UserAdminControl from "./UserAdminControl";
import GoogleSignIn from "./GoogleSignIn";
import { AppProvider } from "./AppContext";
import {AppBar} from "@mui/material";


function App() {
  return (
    <AppProvider>      
        <div className="App-container">
          <header>
            <AppBar position="static">
              <GoogleSignIn />
            </AppBar>
          </header>
          <main>            
            <UserAdminControl /> 
          </main>
          <footer >
            <a href="https://cartells.elmolideponent.com/">&copy;2025 - Cartells de Ponent</a>
          </footer>
        </div>      
    </AppProvider>
  );
}

export default App;
